import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import logo from "../../img/logo.png";

const Headers = () => {
  return (
    <div className="sticky-top barss  pt-3 pt-lg-0 ">
      <Navbar collapseOnSelect expand="lg" className="navbars">
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{
            backgroundColor: "#fff",
            // padding: "10px",
          }}
        >
          <Nav className="me-auto"></Nav>
          <Nav className="mt-2">
            <li className="nav_link">
              <span>Summit 2024</span>
              <ul className="navdropdown">
                <Link to="/business-scenario" className=" text-decoration-none">
                  <li>Business Scenario</li>
                </Link>
                <Link to="/about-nbs-2024" className=" text-decoration-none">
                  <li>About NBS 2024</li>
                </Link>
                <Link to="/schedule" className=" text-decoration-none">
                  <li>Schedule</li>
                </Link>
                <Link
                  to="/all-speakers/organizing-committee"
                  className=" text-decoration-none"
                >
                  <li>Organizing Committee</li>
                </Link>
                <Link to="/faq" className=" text-decoration-none">
                  <li>FAQ</li>
                </Link>
                <Link to="/contact-us" className=" text-decoration-none">
                  <li>Contact Us</li>
                </Link>
              </ul>
            </li>
            {/* <li className="nav_link">
              <span>Pre-Event</span>
              <ul className="navdropdown">
                <Link
                  to="/investment-and-policy-dialogue"
                  className=" text-decoration-none"
                >
                  <li>Investment &amp; Policy Dialogue</li>
                </Link>
                <Link to="/koshi-province" className=" text-decoration-none">
                  <li>Koshi Province</li>
                </Link>
                <Link to="/madesh-province" className=" text-decoration-none">
                  <li>Madesh Province</li>
                </Link>
                <Link to="/gandaki-province" className=" text-decoration-none">
                  <li>Gandaki Province</li>
                </Link>
                <Link to="/lumbini-province" className=" text-decoration-none">
                  <li>Lumbini Province</li>
                </Link>
                <Link to="/karnali-province" className=" text-decoration-none">
                  <li>Karnali Province</li>
                </Link>
                <Link
                  to="/sudur-paschim-province"
                  className=" text-decoration-none"
                >
                  <li>Sudur Paschim Province</li>
                </Link>
              </ul>
            </li> */}
            <Link
              to="/event-activities"
              className=" text-decoration-none text-dark"
            >
              <li className="nav_link">
                <span>Event Activities</span>
              </li>
            </Link>
            <Link to="/" className=" text-decoration-none text-dark">
              <li className="nav_link">
                <span>Agenda</span>
              </li>
            </Link>
            <li className="nav_link">
              <Link
                to="/all-speakers/distinguished-guests"
                className="text-dark text-decoration-none"
              >
                <span>Speakers</span>
              </Link>
            </li>
            <li className="nav_link">
              <Link to="/partners" className="text-dark text-decoration-none">
                <span>Partners</span>
              </Link>
            </li>
            <li className="nav_link">
              <Link to="/" className="text-dark text-decoration-none">
                <span>Media Room</span>
              </Link>
            </li>
            {/* <li className="nav_link">
              <span>Media Room</span>
              <ul className="navdropdown">
                <li>Press Release</li>
                <Link to="/news" className=" text-decoration-none">
                  <li>News Coverage</li>
                </Link>

                <li>Videos</li>
                <li>Gallery</li>
                <li>Reports</li>
              </ul>
            </li> */}
            {/* <li className="nav_link">
              <span>FAQ</span>
            </li> */}
          </Nav>

          <Nav className="mt-2">
            <button className="stall_button">Book a Stall</button>
          </Nav>
          <Nav className="mt-2">
            <button className="head_register_button">
              Register Now for NBS 2024
            </button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Headers;
