import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AllSpeaker from "./pages/AllSpeaker";
import Article from "./pages/Article";
import Register from "./pages/Register";
import Organizer from "./pages/Organizer";
import News from "./pages/News";
import LandScape from "./pages/innerPage/LandScape";
import Faq from "./pages/innerPage/Faq";
import Partners from "./pages/Partners";
import AllPartners from "./pages/innerPage/AllPartners";
import Activities from "./pages/innerPage/Activities";

const App = lazy(() => import("./App"));
const Schedule = lazy(() => import("./pages/Schedule"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Business = lazy(() => import("./pages/Business"));

function Routess() {
  return (
    <>
      <Router>
        <Suspense fallback={<></>}>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/about-nbs-2024" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/business-scenario-in-nepal" element={<Business />} />
            <Route path="/register" element={<Register />} />
            <Route path="/article-competition" element={<Article />} />
            <Route path="/all-speakers/:key" element={<AllSpeaker />} />
            <Route path="/business-scenario" element={<LandScape />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/organizer" element={<Organizer />} />
            <Route path="/news" element={<News />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/partners" element={<AllPartners />} />
            <Route path="/event-activities" element={<Activities />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default Routess;
