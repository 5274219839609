import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

const Partners = () => {
  return (
    <div
      className="partners container pt-5 pb-4"
      style={{ borderBottom: "3px solid #14469f" }}
    >
      <div className="partners-head pb-3 pb-md-5">
        <h3>Joint Initiative</h3>
      </div>
      <div className="row w-100">
        <div className="col-md-4 mb-5 mb-md-0 partner ps-5 pe-5 ps-md-5 pe-md-5">
          <center>
            <img src="./images/partners/organizer/sarkar.jpg" alt="..." />
            <br />
            <span
              style={{
                color: "#0b76bc",
              }}
            >
              Ministry of Industry,Commerce & Supply
            </span>
          </center>
        </div>
        <div className="col-md-4 mb-5 mb-md-0 partner">
          <center>
            <img src="./images/partners/organizer/cbfin.png" alt="..." />
          </center>
        </div>
        <div className="col-md-4 mb-3">
          <center>
            <img
              src="./images/partners/organizer/nbi.jpg"
              className="w-100 ps-5 pe-5 nbi"
              alt="..."
            />
          </center>
        </div>
      </div>
    </div>
  );
};

export default Partners;
